import Layout from "./components/Layout/Layout";

const NotFound = () => {
  return (
    <Layout>
      <div style={{height: '60vh'}} className="not-found">
        <h1>404</h1>
        <p>Oops! Page not found.</p>
        <a href="/">Go Home</a>
      </div>
    </Layout>
  );
};

export default NotFound;
