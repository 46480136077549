const Detail = ({ symbol, content }) => (
  <>
    {content && (
      <>
        {symbol && <strong style={{ paddingRight: "3px" }}>{symbol}</strong>}
        <span style={{ paddingRight: "3px" }}>{content ? content : "?"}</span>
      </>
    )}
  </>
);

export default Detail