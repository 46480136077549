import React from "react";
import { Col, Row } from "react-bootstrap";
import RecentMemberData from "./RecentMemberData";
import { useEffect, useState } from "react";
import Xarrow from "react-xarrows";

const RecentMembers = ({ familyMembers }) => {
  const [formattedMembers, setFormattedMembers] = useState();
  const [draw, setDraw] = useState(false);

  useEffect(() => {
    const formatMembers = () => {
      const upperNodes = ["l-1", "l-2"];
      const upperMiddleNodes = [...upperNodes, "l-3"];
      const adjusted = familyMembers?.map((fm) => ({
        paddingSide: upperNodes.includes(fm.node),
        paddingBottom: upperMiddleNodes.includes(fm.node),
        colXs: 10,
        colSm: 10,
        colMd: upperNodes.includes(fm.node) ? 6 : 10,
        colLg: upperNodes.includes(fm.node) ? 6 : 10,
        lineColor: upperNodes.includes(fm.node) ? '#192634' : '#d4dfea',
        ...fm,
      }));
      setFormattedMembers(adjusted);
    };

    const timeout = () => {
      setTimeout(() => {
        setDraw(true);
      }, 1000);
    };

    formatMembers();
    timeout();
  }, [familyMembers]);

  return (
    <>
      {formattedMembers?.map((member) => (
        <React.Fragment key={`fragment-${member.id}`}>
          {draw && (
            <Xarrow
              key={`xarrow-${member.id}`}
              start={`${member.id}-left`}
              end={member.id}
              lineColor={member.lineColor}
              headColor={member.lineColor}
              strokeWidth={1}
              curveness={1}
              dashness={false}
              startAnchor="left"
              endAnchor="left"
              path="grid"
              showHead={false}
              headSize={6}
            />
          )}
          <Row
          key={`top-row-${member.id}`}
            style={{
              marginBottom: member.paddingBottom ? "50px" : "0px",
            }}
          >
            {member.paddingSide && (
              <Col
                key={`${member.id}-left-conditional`}
                xs={0}
                sm={0}
                md={2}
              ></Col>
            )}
            <Col
              key={`${member.id}-left`}
              id={`${member.id}-left`}
              xs={1}
            ></Col>
            <Col
              key={member.id}
              className={member.color_class}
              style={{
                border: "#d4dfea solid 1px",
                borderRadius: "10px",
              }}
              id={member.id}
              xs={member.colXs}
              sm={member.colSm}
              md={member.colMd}
              lg={member.colLg}
            >
              <RecentMemberData key={`recent-member-data-${member.id}`} memberData={member} />
              <RecentMembers key={`recent-member-${member.id}`} familyMembers={member?.children} />
            </Col>

            <Col
              key={`${member.id}-right`}
              id={`${member.id}-right`}
              xs={1}
            ></Col>
          </Row>
        </ React.Fragment>
      ))}
    </>
  );
};

export default RecentMembers;
