import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Legend = () => {
  const { t } = useTranslation();

  return (
    <>
      <Col xs={0} sm={0} md={2}></Col>
      <Col xs={1}></Col>
      <Col
      className="legend"
        xs={10}
        sm={10}
        md={6}
        lg={6}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <strong>{t('tree_legend')}</strong>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <span>* - {t('tree_legend_born')}</span>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <span>† - {t('tree_legend_died')}</span>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <span>x - {t('tree_legend_married')}</span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            color: "#fff",
          }}
        >
          <span># - {t('tree_legend_number')}</span>
        </div>
      </Col>
      <Col xs={1}></Col>
    </>
  );
};

export default Legend;
