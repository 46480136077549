import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const CommentForm = ({
  show,
  handleClose,
  onSubmit,
  isSubmitting,
  setIsSubmitting,
}) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    name: "",
    url: "",
    city: "",
    country: "",
    comment: "",
    commented_at: `${new Date().toLocaleString()} UTC +2 `,
  });

  const [submitError, setSubmitError] = useState(false);

  const [errors, setErrors] = useState({});

  const [isLocalhost] = useState(window.location.hostname === "localhost");

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (!formData.comment.trim()) {
      newErrors.comment = t("please_fill_comment");
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSumitForm = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitting(true);
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        return;
      }
      executeRecaptcha("commentFormSubmit").then((gReCaptchaToken) => {
        submitEnquiryForm(gReCaptchaToken);
      });
    }
  };

  const submitEnquiryForm = async (token) => {
    if (isLocalhost) {
      onSubmit(formData);
    } else {
      try {
        const verification = await fetch(
          process.env.REACT_APP_RECAPTCHA_VERIFY_URL,
          {
            method: "POST",
            body: JSON.stringify({
              secret: process.env.REACT_APP_RECAPTCHA_SECRET_KEY,
              response: token,
            }),
          }
        );

        const resultJSON = await verification.json();

        if (resultJSON.success) {
          onSubmit(formData);
        } else {
          setSubmitError(true);
        }
      } catch (error) {
        console.error("Error during server verification:", error);
      }
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("leave_a_comment")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="form-group-padding" controlId="formName">
            <Form.Control
              type="text"
              placeholder={t("enter_your_name")}
              name="name"
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="form-group-padding" controlId="formWebsite">
            <Form.Control
              type="text"
              placeholder={t("enter_your_website")}
              name="url"
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="form-group-padding" controlId="formCity">
            <Form.Control
              type="text"
              placeholder={t("enter_your_city")}
              name="city"
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="form-group-padding" controlId="formCountry">
            <Form.Control
              type="text"
              placeholder={t("enter_your_country")}
              name="country"
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="form-group-padding" controlId="formComment">
            <Form.Control
              as="textarea"
              rows={3}
              placeholder={t("leave_your_comment")}
              name="comment"
              onChange={handleChange}
            />
            {errors.comment && <span style={{color: 'red'}}>{errors.comment}</span>}
          </Form.Group>

          <Form.Group className="form-group-padding" controlId="formComment">
            <Form.Label>{t("comment_added")}</Form.Label>
            <Form.Control
              disabled
              type="text"
              name="commented_at"
              value={formData.commented_at}
            />
          </Form.Group>

          {submitError && (
            <Form.Group className="form-group-padding" controlId="formComment">
              <Form.Label>{t("submit_error")}</Form.Label>
            </Form.Group>
          )}

          <div className="form-group-padding" style={{ textAlign: "right" }}>
            <Button
              className="comment-button-element"
              disabled={isSubmitting}
              onClick={(e) => handleSumitForm(e)}
            >
              {isSubmitting ? "Submitting..." : t("add_comment")}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CommentForm;
