import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
// import devFamilyData from "../../../config/develoment/arecenthistory.json";
import Layout from "../../Layout/Layout";
import RecentMembers from "./RecentMembers";
import Xarrow from "react-xarrows";
import OverlaySpinner from "../../shared/OverlaySpinner";
import Legend from "../shared/Legend";
import familyDataRaw from "../../../config/arecenthistory.json"

const FamilyTreeChildren = () => {
  const [draw, setDraw] = useState(false);
  const [familyData, setFamilyData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [anchors, setAnchors] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      let data = null;
      try {
      //   if (window.location.hostname === "nahnah") {
      //     data = devFamilyData
      //     setFamilyData(data);
      //   } else {
      //     const response = await fetch(
      //       `${process.env.REACT_APP_GET_DATA_BASE_URL}?field=arecenthistory`
      //     );

      //     if (response.ok) {
      //       const resultJSON = await response.json();
      //       data = resultJSON[0]["arecenthistory"];
      //       setFamilyData(data);
      //     } else {
      //       console.error(
      //         "Error fetching data for a recent history, see function"
      //       );
      //     }
      //   }
      data = familyDataRaw["arecenthistory"];
      setFamilyData(data);
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        const familyMembers = data?.children;
        if (familyMembers) {
          const startAnchor = `${familyMembers[0]?.id}-left`;
          const endAnchor = `${
            familyMembers[familyMembers.length - 1]?.id
          }-left`;

          setAnchors({ startAnchor: startAnchor, endAnchor: endAnchor });
        }
        setTimeout(() => {
          setDraw(true);
        }, 1000);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <Layout>
      {loading && (
        <OverlaySpinner opacity={1} />
      )}
      {!loading && (
        <Container>
          <Row style={{ marginBottom: "50px" }}>
            <Legend />
          </Row>
          {familyData && (
            <>
              <RecentMembers familyMembers={familyData.children} />
              {!draw && <OverlaySpinner opacity={1} />}
              {draw && (
                <>
                  <Xarrow
                    start={anchors.startAnchor}
                    end={anchors.endAnchor}
                    lineColor="#000"
                    headColor="#000"
                    strokeWidth={1}
                    curveness={1}
                    dashness={false}
                    startAnchor="left"
                    endAnchor="left"
                    path="grid"
                    showHead={false}
                    headSize={6}
                  />
                </>
              )}
            </>
          )}
        </Container>
      )}
    </Layout>
  );
};

export default FamilyTreeChildren;
