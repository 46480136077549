import { useState, useEffect, createContext, useContext } from "react";
import navigationConfiguration from "../config/navigation-config.json";

const GlobalContext = createContext(null);

const initialState = {
  navBar: [],
  loading: false
};

export const GlobalState = (props) => {
  const [globalState, setGlobalState] = useState(initialState);

  const updateGlobalState = (key, newValue) => {
    setGlobalState((oldState) => {
      if (oldState[key] !== newValue) {
        const newState = { ...oldState };
        newState[key] = newValue;
        return newState;
      } else {
        return oldState;
      }
    });
  };

  useEffect(() => {
    updateGlobalState("loading", true);
    updateGlobalState("navBar", navigationConfiguration.nav_bar);
    updateGlobalState("loading", false);
  }, []);

  return (
    <GlobalContext.Provider value={[globalState, updateGlobalState]}>
      {props.children}
    </GlobalContext.Provider>
  );
};

export const useGlobalState = () => useContext(GlobalContext);
