import Layout from "./Layout/Layout";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Image } from "react-bootstrap";
import TypingAnimation from "./animations/TypingAnimation";
import dupreezcrest from "../images/dupreezcrest-edit-transformed.avif";
import signature from "../images/signat1-transformed-extra.avif";

const Home = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Container>
        <Row>
          <Col lg={1} xl={2}></Col>
          <Col style={{ textAlign: "center" }} lg={5} xl={4}>
            <Col md={12}>
              <span>{t("du_preez_meaning")}:</span>
              <p className="man-from-the-house">
                "{t("man_from_the_house_at_the_meadows")}"
              </p>
            </Col>
            <Col md={12}>
              <Image
                alt="Signature of the first Du Preez in South Africa"
                src={signature}
                height={88}
                width={210}
                style={{filter: 'drop-shadow(2px 2px 2px #222)'}}
              />
              <p style={{fontStyle: 'italic'}}>{t("first_signature")}</p>
            </Col>
            <Col md={12}>{t("oorwegend_afrikaans")}</Col>
          </Col>
          <Col style={{ textAlign: "center" }} lg={5} xl={4}>
            <Col md={12}>
              <TypingAnimation
                words={[
                  "Du Preez",
                  "Des Prez",
                  "Des Pres",
                  "Du Pré",
                  "Du Pree",
                  "De Pree",
                  "Dupreez",
                  "Dupree",
                ]}
              />
            </Col>
            <Col md={12}>
              <Image
                alt="My Du Preez coat of arms"
                src={dupreezcrest}
                height={274}
                width={199}
                style={{filter: 'drop-shadow(3px 3px 3px #222)'}}
              />
            </Col>
          </Col>
          <Col lg={1} xl={2}></Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Home;
