import { useState, useEffect, useMemo } from "react";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./locales/en/translation.json";
import translationAF from "./locales/af/translation.json";
import translationFR from "./locales/fr/translation.json";

import Home from "./components/Home";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useGlobalState } from "./components/GlobalState";
import { LinearProgress } from "@mui/material";

import navigationConfiguration from "./config/navigation-config.json";
import Guestbook from "./components/guestbook/Guestbook";
import History from "./components/history/History";
import ThysDuPreez from "./components/personal/ThysDuPreez";
import FamilyTree from "./components/familytree/FamilyTree";
import FamilyTreeChildren from "./components/familytree/arecenthistory/FamilyTreeChildren";
import Biography from "./components/biography/Biography";
import NotFound from "./NotFound";

function App() {
  const [navLinks, setNavLinks] = useState(null);

  useEffect(() => {
    const resources = {
      en: { translation: translationEN },
      af: { translation: translationAF },
      fr: { translation: translationFR },
    };

  setNavLinks(navigationConfiguration.links)

    i18n.use(initReactI18next).init({
      resources,
      lng: "en",
      fallbackLng: "en",
      interpolation: {
        escapeValue: false,
      },
    });
  }, []);

  const cachedNavLinks = useMemo(() => navLinks, [navLinks]);

  const [globalState] = useGlobalState();
  const { loading } = globalState;

  if (loading) {
    return (
      <LinearProgress />
    );
  } else
    return (
      <BrowserRouter>
        {cachedNavLinks && !loading && (
          <Routes>
            <Route path={cachedNavLinks.home} element={<Home />} />
            <Route
              path={cachedNavLinks.du_preez_pages_history}
              element={<History />}
            />
            <Route path={cachedNavLinks.guest_book_label} element={<Guestbook />} />
            <Route
              path={cachedNavLinks.personal_pages_du_preez_tree}
              element={<FamilyTree />}
            />
            <Route
              path={cachedNavLinks.personal_pages_du_preez_tree_children}
              element={<FamilyTreeChildren />}
            />
            <Route
              path={cachedNavLinks.personal_pages_biography}
              element={<Biography />}
            />
            <Route
              path={cachedNavLinks.thys_du_preez}
              element={<ThysDuPreez />}
            />
            <Route
              path="*"
              element={<NotFound   />}
            />
          </Routes>
        )}
      </BrowserRouter>
    );
}

export default App;
