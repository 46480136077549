import { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import { Container, Row, Col, Button } from "react-bootstrap";
import CommentForm from "./CommentForm";
import { useTranslation } from "react-i18next";
import GuestbookContent from "./GuestbookContent";
import devGuestbookData from "../../config/develoment/guestbook.json";
import OverlaySpinner from "../shared/OverlaySpinner";
import emailjs from "@emailjs/browser";

const Guestbook = () => {
  const [guestbookData, setGuestbookData] = useState([]);
  const [showCommentForm, setShowCommentForm] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isLocal] = useState(false); //useState(window.location.hostname === "localhost");
  const { t } = useTranslation();

  const handleCommentFormToggle = () => {
    setShowCommentForm(!showCommentForm);
  };

  const handleCommentFormSubmit = async (formData) => {
    if (!isLocal) {
      await fetch(process.env.REACT_APP_SET_DATA_BASE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          field: "guestbook",
          formData: formData,
        }),
      });
    }

    let simulatedForm = document.createElement("form");
    const createHiddenInput = (name, value) => {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = name;
      input.value = value;
      return input;
    };

    simulatedForm.appendChild(createHiddenInput("from_name", formData.name));
    simulatedForm.appendChild(createHiddenInput("to_name", "Grobler"));
    simulatedForm.appendChild(createHiddenInput("name", formData.name));
    simulatedForm.appendChild(createHiddenInput("website", formData.url));
    simulatedForm.appendChild(createHiddenInput("city", formData.city));
    simulatedForm.appendChild(createHiddenInput("comment", formData.comment));
    simulatedForm.appendChild(createHiddenInput("commented_at", formData.commented_at));

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        simulatedForm,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY_ID
      )
      .then(
        () => {
          console.log("Email sent successfully");
        },
        (error) => {
          console.log(error);
          console.log("Email failed to send");
        }
      );

    setGuestbookData([{ entry: formData }, ...guestbookData]);
    setIsSubmitting(false);
    setShowCommentForm(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      let formattedData = [];
      try {
        setLoading(true);
        if (window.location.hostname === "nahnah") {
          Object.entries(devGuestbookData).forEach((entry) => {
            const [key, value] = entry;
            formattedData.push({ id: key, entry: value });
          });

          setGuestbookData(formattedData.reverse());
        } else {
          const response = await fetch(`${process.env.REACT_APP_GET_DATA_BASE_URL}?field=guestbook`);

          if (response.ok) {
            const resultJSON = await response.json();
            Object.entries(resultJSON).forEach((entry) => {
              const [key, value] = entry;
              formattedData.push({ id: key, entry: value });
            });

            setGuestbookData(formattedData.reverse());
          } else {
            console.error("Error fetching data for guestbook, see function");
          }
        }
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <Layout>
      {loading && <OverlaySpinner opacity={1} />}
      {!loading && (
        <>
          <Container>
            <Row>
              <Col md={6}></Col>
              <Col className="comment-button" md={6}>
                <Button className="comment-button-element" onClick={handleCommentFormToggle}>
                  {t("add_comment")}
                </Button>
              </Col>
            </Row>
            <GuestbookContent data={guestbookData} />
          </Container>

          <CommentForm
            show={showCommentForm}
            handleClose={handleCommentFormToggle}
            onSubmit={handleCommentFormSubmit}
            isSubmitting={isSubmitting}
            setIsSubmitting={setIsSubmitting}
          />
        </>
      )}
    </Layout>
  );
};

export default Guestbook;
