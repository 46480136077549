import Detail from "./Detail";
import { Image } from "react-bootstrap";

const Spouse = ({ spouse, number }) => (
  <>
    <div style={{ display: "flex", justifyContent: "center" }}>
      <span style={{ fontSize: "smaller" }}>{spouse.number}</span>
    </div>
    <div style={{ display: "flex", justifyContent: "center" }}>
      <strong
        style={{
          paddingRight: "3px",
          fontSize: spouse.date ? "smaller" : "normal",
        }}
      >
        {Array.from({ length: number }, (_) => "x")}
      </strong>
      {spouse.date ? (
        <>
          <span style={{ paddingRight: "3px", fontSize: "smaller" }}>
            {spouse.date}
          </span>
          <span style={{ paddingRight: "3px", fontSize: "smaller" }}>
            {spouse.place}
          </span>
        </>
      ) : (
        <span style={{ paddingRight: "3px" }}>{spouse.name}</span>
      )}
    </div>
    {spouse.date && (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <span style={{ paddingRight: "3px", fontSize: "smaller" }}>
          {spouse.name}
        </span>
      </div>
    )}
    <div style={{ display: "flex", justifyContent: "center" }}>
      {spouse.image && <Image height="100px" alt="image" src={require(`../../../images/${spouse.image}`)} />}
    </div>
    <div
      style={{ display: "flex", justifyContent: "center", fontSize: "smaller" }}
    >
      <Detail  symbol="*" content={spouse.born} />
      <Detail
        symbol=""
        content={spouse.hometown}
      />
    </div>
    <div
      style={{ display: "flex", justifyContent: "center", fontSize: "smaller" }}
    >
      <Detail symbol="†" content={spouse.died} />
    </div>
  </>
);

export default Spouse;