import React, { useEffect, useState, useMemo } from "react";
import { FaUser } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const ShowMore = ({ showMore, toggleShowMore, textLenght }) => {
  const [hideShowMore, setHideShowMore] = useState(false);
  const { t } = useTranslation();
  
  useEffect(() => {
    if (textLenght < 201) {
      setHideShowMore(true);
    } else {
      setHideShowMore(false);
    }
  }, [textLenght]);

  if (!hideShowMore) {
    return (
      <>
        {!showMore && <span onClick={toggleShowMore}>{t("read_more")}</span>}
        {showMore && <span onClick={toggleShowMore}>{t("read_less")}</span>}
      </>
    );
  }
  return <></>;
};

const CommentCard = (props) => {
  const [isExpanded, setExpanded] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const [avatarColor, setAvatarColor] = useState("#FFFFFF");
  const [fields, setFields] = useState({
    name: "",
    residence: "",
    commented_at: "",
    website: "",
    shortComment: "",
    longComment: "",
  });
  const { cardData } = props;
  const { t } = useTranslation();

  const toggleComment = () => {
    setExpanded(!isExpanded);
  };

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  function randomColor() {
    let hex = Math.floor(Math.random() * 0xffffff);
    let color = "#" + hex.toString(16);

    return color;
  }

  const color = useMemo(() => randomColor(), []);

  useEffect(() => {
    setAvatarColor(color);
  }, [color]);

  useEffect(() => {
    const titleCase = (title) => {
      if (title !== undefined) {
        const words = title.split(" ");

        if (words.length === 1 && words[0].length < 4) {
          return words[0].toUpperCase();
        }

        return words
          .map((word) => {
            return word[0] && word[0].toUpperCase() + word.substring(1);
          })
          .join(" ");
      }
    };

    const name = (name) => {
      if (name) {
        return titleCase(name);
      } else return t("anonymous");
    };

    const residence = (city, country) => {
      if (city && country) {
        return `from ${titleCase(city)}, ${titleCase(country)}`;
      }
      if (city && !country) {
        return `from ${titleCase(city)}`;
      }
      if (!city && country) {
        return `from ${titleCase(country)}`;
      }
      return "";
    };

    const commented_at = (commented_at) => {
      if (commented_at) {
        return `${t("comment_added")}: ${commented_at}`;
      } else return `${t("comment_added")}: Before January 2024`;
    };

    const website = (url, commented_at) => {
      if (url && commented_at) {
        let target = url
        if (!url.includes('http')){
          target = `https://${url}`
        }
        return <><span>{t("website")}: </span><a href={target} target="_blank" rel="noreferrer">{target}</a></>
      } else return `${t("website")}: ${t("not_specified")}`;
    };

    const shortComment = (comment) => {
      if (!comment) {
        return "";
      }
      if (comment.length > 200) {
        comment = comment.substr(0, 200) + "...";
      }
      return comment;
    };

    const longComment = (comment) => {
      if (!comment) {
        return "";
      }
      else return comment
    }

    setFields({
      name: name(cardData.name),
      residence: residence(cardData.city, cardData.country),
      commented_at: commented_at(cardData.commented_at),
      website: website(cardData.url, cardData.commented_at),
      shortComment: shortComment(cardData.comment),
      longComment: longComment(cardData.comment),
    });
  }, [t, cardData]);

  return (
    <div className="comment-card">
      <div className={isExpanded ? "comment-header" : "comment-header border-bottom-radius"} onClick={toggleComment}>
        <div className="avatar-icon" style={{ backgroundColor: avatarColor }}>
          <FaUser />
        </div>
        <h4>
          {fields.name} {fields.residence}
        </h4>
        <span className={`toggle-icon ${isExpanded ? "expanded" : ""}`}>{isExpanded ? "-" : "+"}</span>
      </div>
      {isExpanded && (
        <div className="comment-body">
          <p className="comment-date">
            {fields.commented_at} | {fields.website}
          </p>
          {!showMore && <p>{fields.shortComment}</p>}
          {showMore && <p>{fields.longComment}</p>}
          <ShowMore
            showMore={showMore}
            toggleShowMore={toggleShowMore}
            textLenght={fields.longComment.length}
          />
        </div>
      )}
    </div>
  );
};

export default CommentCard;
