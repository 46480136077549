import { Image } from "react-bootstrap";
import { useEffect, useState } from "react";
import Detail from "../shared/Detail";
import Spouse from "../shared/Spouse";

const RecentMemberData = ({ memberData }) => {
  const [member, setMember] = useState();

  useEffect(() => {
    const processImage = () => {
      const rawMember = memberData;
      if (!rawMember.image) {
        rawMember.image = "silhouette-male-640x640-600x600.avif";
      }
      setMember(rawMember);
    };

    processImage();
  }, [memberData]);

  return (
    <>
      {member && (
        <div style={{ padding: "5px" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <strong style={{ fontSize: "smaller" }}>{member.level}</strong>
            <span
              style={{
                fontSize: "smaller",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              {member.number ? "-" : ""}
            </span>
            <span style={{ fontSize: "smaller" }}>{member.number}</span>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <strong>{member.name}</strong>
          </div>
          {member.image && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Image
                key={`image-member-${member.id}`}
                height="100px"
                alt="image"
                src={require(`../../../images/${member.image}`)}
              />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "smaller",
            }}
          >
            <Detail
              key={`born-${member.id}`}
              symbol="*"
              content={member.born}
            />
            <Detail
              key={`hometown-${member.id}`}
              symbol=""
              content={member.hometown}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "smaller",
            }}
          >
            <Detail
              key={`died-${member.id}`}
              symbol="†"
              content={member.died}
            />
          </div>
          {member.married?.map((spouse, index) => (
            <Spouse
              key={`spouse-${member.id}-${index}`}
              spouse={spouse}
              number={index + 1}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default RecentMemberData;
