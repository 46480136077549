import Layout from "../Layout/Layout";
import { Container, Row, Col, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import dupreezcrest from "../../images/dupreezcrest-edit-transformed.avif";
import attestaat from "../../images/attestaat.avif";
import image1 from "../../images/605860d6-513a-4446-8390-860fc0ecd8f8.avif"
import image2 from "../../images/monument2.avif"
import image3 from "../../images/monument3.avif"
import image4 from "../../images/monument6.avif"
import image5 from "../../images/europe2.avif"
import image6 from "../../images/duppurp1.avif"
import image7 from "../../images/monument4.avif"
import image8 from "../../images/cotoarms.avif"
import image9 from "../../images/104.avif"

const History = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Container>
        <Row>
          <Col md={12} lg={6}>
            <h4 className="header-style layout-header">
              {t("du_preez_pages_history")}
            </h4>
            <p>{t("du_preez_pages_history_content_one")}</p>
            <p>{t("du_preez_pages_history_content_two")}</p>
            <p>{t("du_preez_pages_history_content_three")}</p>
            <p>{t("du_preez_pages_history_content_four")}</p>
          </Col>
          <Col md={12} lg={6}>
            <h4 className="header-style layout-header">
              {t("du_preez_pages_event_before")}
            </h4>
            <p>{t("du_preez_pages_event_before_content_one")}</p>
            <p
              dangerouslySetInnerHTML={{
                __html: t("du_preez_pages_event_before_content_two"),
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: t("du_preez_pages_event_before_content_three"),
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ textAlign: "center" }} md={12}>
            <h4 className="header-style layout-header">
              {t("du_preez_pages_first_in_sa")}
            </h4>
          </Col>
          <Col md={12} lg={6}>
            <p
              dangerouslySetInnerHTML={{
                __html: t("du_preez_pages_first_in_sa_content_one"),
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: t("du_preez_pages_first_in_sa_content_two"),
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: t("du_preez_pages_first_in_sa_content_three"),
              }}
            />
          </Col>
          <Col md={12} lg={6}>
            <p
              dangerouslySetInnerHTML={{
                __html: t("du_preez_pages_first_in_sa_content_four"),
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: t("du_preez_pages_first_in_sa_content_five"),
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: t("du_preez_pages_first_in_sa_content_six"),
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: t("du_preez_pages_first_in_sa_content_seven"),
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: t("du_preez_pages_first_in_sa_content_eight"),
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ textAlign: "center" }} md={12}>
            <h4 className="header-style layout-header">
              {t("du_preez_pages_certificate")}
            </h4>
          </Col>
          <Col md={12} lg={6}>
            <p
              dangerouslySetInnerHTML={{
                __html: t("du_preez_pages_certificate_content_one"),
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: t("du_preez_pages_certificate_content_two"),
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: t("du_preez_pages_certificate_content_three"),
              }}
            />
          </Col>
          <Col
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
            md={12}
            lg={6}
          >
            <Image
              alt="Cirtificate of Church Membership"
              src={attestaat}
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ textAlign: "center" }} md={12}>
            <h4 className="header-style layout-header header-padding">
              {t("du_preez_pages_monument")}
            </h4>
          </Col>
          <Col style={{ textAlign: "center" }} md={12}>
            <p>{t("du_preez_pages_monument_content_one")}</p>
            <p
              dangerouslySetInnerHTML={{
                __html: t("du_preez_pages_monument_content_two"),
              }}
            />
          </Col>
          <Col
            className="centered"
            style={{ paddingTop: 10 }}
            lg={3}
            md={6}
            sm={12}
          >
            <Image
              width="100%"
              alt="Du Preez Monument 1"
              src={image1}
            />
          </Col>
          <Col
            className="centered"
            style={{ paddingTop: 10 }}
            lg={3}
            md={6}
            sm={12}
          >
            <Image
              width="100%"
              alt="Du Preez Monument 2"
              src={image2}
            />
          </Col>
          <Col
            className="centered"
            style={{ paddingTop: 10 }}
            lg={3}
            md={6}
            sm={12}
          >
            <Image
              width="100%"
              alt="Du Preez Monument 3"
              src={image3}
            />
          </Col>
          <Col
            className="centered"
            style={{ paddingTop: 10 }}
            lg={3}
            md={6}
            sm={12}
          >
            <Image
              width="100%"
              alt="Du Preez Monument 6"
              src={image4}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Col style={{ textAlign: "center" }} md={12}>
              <h4 className="header-style layout-header header-padding">
                {t("du_preez_pages_origins")}
              </h4>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("du_preez_pages_origins_content"),
                }}
              />
            </Col>
            <Col style={{ paddingTop: 10, textAlign: "center" }} md={12}>
              <Image
                width="100%"
                alt="Plaas 3"
                src={image5}
              />
            </Col>
          </Col>
          <Col md={6}>
            <Row>
              <Col style={{ justifyContent: "center" }} md={12}>
                <h4 className="header-style layout-header header-padding">
                  {t("du_preez_pages_coat_of_arms")}
                </h4>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("du_preez_pages_coat_of_arms_content"),
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  paddingTop: 20,
                }}
                md={3}
                xs={6}
              >
                <Image
                  width="100%"
                  alt="Du Preez coat of arms 1"
                  src={dupreezcrest}
                />
              </Col>
              <Col
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  paddingTop: 20,
                }}
                md={3}
                xs={6}
              >
                <Image
                  width="100%"
                  alt="Du Preez coat of arms 2"
                  src={image6}
                />
              </Col>

              <Col
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  paddingTop: 20,
                }}
                md={3}
                xs={6}
              >
                <Image
                  width="100%"
                  alt="Du Preez coat of arms 3"
                  src={image7}
                />
              </Col>
              <Col
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  paddingTop: 20,
                }}
                md={3}
                xs={6}
              >
                <Image
                  width="100%"
                  alt="Du Preez coat of arms 4"
                  src={image8}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col style={{ justifyContent: "center" }} md={12}>
            <h4 className="header-style layout-header header-padding">
              {t("du_preez_pages_sites")}
            </h4>
          </Col>
          <Col style={{ justifyContent: "center" }} md={6}>
            <p>{t("du_preez_pages_sites_content")}</p>
            <Image
              width="100%"
              alt="Du Preez coat of arms 4"
              src={image9}
            />
          </Col>
          <Col md={6}>
            <p>{t("du_preez_pages_sites_content_one")}</p>
            <ul>
              <li>
                <a
                  href="http://www.museum.co.za/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The Huguenot museum of South Africa
                </a>
              </li>
              <li>
                <a
                  href="http://www.hugenoot.org.za/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The Huguenot Society of South Africa
                </a>
              </li>
              <li>
                <a
                  href="http://en.wikipedia.org/wiki/Huguenot_Monument"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The Huguenot museum of South Africa - Wikipedia article
                </a>
              </li>
              <li>
                <a
                  href="http://www.orange-street-church.org/text/huguenot.htm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The Huguenot Heritage
                </a>
              </li>
              <li>
                <a
                  href="http://www.lepg.org/wars.htm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The wars of religion, part 1
                </a>
              </li>
              <li>
                <a
                  href="http://www.lepg.org/wars2.htm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The wars of religion, part 2
                </a>
              </li>
              <li>
                <a
                  href="http://en.wikipedia.org/wiki/Huguenots_in_South_Africa"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Huguenots in South Africa - Wikipeadia article
                </a>
              </li>
              <li>
                <a
                  href="https://huguenotsociety.org.za/product/die-du-preez-familie-in-suid-afrika-volume-1-boek/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Du Preez-familie in Suid Afrika boek (deel 1 - 3)
                </a>
              </li>
              <li>
                <a
                  href="https://upjournals.up.ac.za/index.php/historia/article/view/1680/1571"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Hercules Des Prez and Cecilia D'Athis
                </a>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h4 className="header-style layout-header header-padding">
              {t("du_preez_pages_ancestors")}
            </h4>
            <p
              dangerouslySetInnerHTML={{
                __html: t("du_preez_pages_ancestors_content_one"),
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: t("du_preez_pages_ancestors_content_two"),
              }}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default History;
