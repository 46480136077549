import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Layout from "../Layout/Layout";

const Biography = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Row>
        <Col xs={12}>
          <h6
            dangerouslySetInnerHTML={{
              __html: t("biography_links"),
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col style={{ paddingTop: "30px" }} xs={12}>
          <h4>{t('biography_extract_header')}</h4>
          {t("biography")}
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <h4 style={{ paddingTop: "30px" }}>{t("biography_more_header")}</h4>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>{t("biography_more_info")}</Col>
      </Row>
      <Row>
        <Col xs={12}>
          <p dangerouslySetInnerHTML={{
              __html: t("biography_read_more"),
            }} />
        </Col>
      </Row>
    </Layout>
  );
};

export default Biography;
