import React, { useState } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useGlobalState } from "../GlobalState";
import { useTranslation } from "react-i18next";
import { changeRoute } from "../../utils/navigation";
import { useNavigate, useLocation } from "react-router-dom";

const NavBar = (props) => {
  const [globalState] = useGlobalState();
  const { navBar } = globalState;
  const { i18n, t } = useTranslation();
  const location = useLocation();

  const observer = new MutationObserver(() => {
    document.querySelectorAll(".active-link").forEach((link) => {
      link.classList.remove("active-link");
    });

    const links = document.querySelectorAll(
      `a[data-to="${location.pathname}"]`
    );
    links.forEach((link) => {
      link.classList.add("active-link");
    });
  });

  observer.observe(document, { childList: true, subtree: true });

  const languages = [{ value: "en" }, { value: "af" }, { value: "fr" }];

  const [currentLanguage, setLanguage] = useState(i18n.language);

  const handleLanguageChange = (key) => {
    i18n.changeLanguage(key);
    setLanguage(key);
  };

  const reactNavication = useNavigate();

  const routeChange = (link, scope) => {
    if (link) {
      changeRoute(link, reactNavication);
    }
  };

  const links = (nav, index) => {
    if (nav.link) {
      return (
        <Nav.Link
          key={`link-${index}`}
          onClick={(event) => routeChange(nav.link, event)}
          data-to={nav.link}
          className="divider"
        >
          {t(nav.key)}
        </Nav.Link>
      );
    }
    return (
      <NavDropdown key={index} title={t(nav.key)} id="collasible-nav-dropdown">
        {nav.links.map((sub, index) => (
          <React.Fragment key={`link-${index}`}>
            <NavDropdown.Item
              key={`drop-link-${index}`}
              data-to={sub.link}
              onClick={(event) => routeChange(sub.link, event)}
            >
              <span>{t(sub.key)}</span>
            </NavDropdown.Item>
            {nav.links.length !== index + 1 && (
              <NavDropdown.Divider key={`divider-${index}`} />
            )}
          </React.Fragment>
        ))}
      </NavDropdown>
    );
  };

  return (
    <Navbar className="glass-navbar" collapseOnSelect expand="lg" fixed="top">
      <Navbar.Brand className="header-style">Du Preez</Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto" style={{ width: "100%" }}>
          {navBar.map((nav, index) => links(nav, index))}
          <Nav.Link
            onClick={() => routeChange("http://www.groblerdupreez.co.za")}
            className="divider web-link"
          >
            {t("personal_pages_grobler")}
          </Nav.Link>
        </Nav>
        <NavDropdown
          key="language-dropdown"
          title={`${t("language")}: ${t(currentLanguage)}`}
          id="collasible-nav-dropdown"
        >
          {languages.map((language, index) => (
            <React.Fragment key={`${language.key}-${index}`}>
              <NavDropdown.Item
                key={`${language.key}-${index}`}
                onClick={() => handleLanguageChange(language.value)}
              >
                {t(language.value)}
              </NavDropdown.Item>
              {languages.length !== index + 1 && (
                <NavDropdown.Divider key={`lang-divider-${index}`} />
              )}
            </React.Fragment>
          ))}
        </NavDropdown>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
