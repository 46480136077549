import React, { useEffect, useState } from "react";
import Navbar from "../navbar/NavBar";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Breadcrumb } from "react-bootstrap";
import { changeRoute } from "../../utils/navigation";
import { useNavigate, useLocation, Link } from "react-router-dom";
import navigationLinks from "../../config/navigation-config.json";
import { buildBreadcrum, filterObject } from "../../utils/utils";

const Layout = ({ children, cachedNavBarData }) => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const [breadcrumbs, setBreadcrums] = useState(["home"]);
  const reactNavication = useNavigate();
  const location = useLocation();

  const routeChange = (e, link) => {
    e.preventDefault();
    if (link) {
      changeRoute(link, reactNavication);
    }
  };

  useEffect(() => {
    const updateBreadcrumbs = () => {
      const path = location.pathname;

      const nodes = path.split("/").filter((p) => p !== "");
      const nodesCount = nodes.length;
      const links = Array.from({ length: nodesCount }, (_, index) =>
        index > 0 ? `/${nodes[index - 1]}` : path
      );

      const keysObj = filterObject(navigationLinks.links, (key, value) =>
        links.includes(value)
      );
      const keys = Object.keys(keysObj);

      const builtBreadcrumbs = buildBreadcrum(keys[0], keys[1]);
      setBreadcrums(builtBreadcrumbs);
    };

    updateBreadcrumbs();
  }, [location.pathname]);

  return (
    <>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
        scriptProps={{
          async: false,
          defer: false,
          nonce: undefined,
        }}
      >
        <div className="d-flex flex-column min-vh-100">
          <Container>
            <Row>
              <Col sx={12}>
                <Navbar />
              </Col>
            </Row>
          </Container>
          <Container className="layout">
            <Row>
              <Col sx={12}>
                <Breadcrumb>
                  {breadcrumbs.map((crumb, index) => (
                    <React.Fragment key={`fragment-${index}`}>
                      <Breadcrumb.Item key={index} active className="notice-me">
                        {breadcrumbs.length - index !== 1 ? (
                          <a
                            className="notice-me"
                            href="/"
                            role="button"
                            onClick={(e) =>
                              routeChange(e, navigationLinks.links[crumb])
                            }
                          >
                            {t(crumb)}
                          </a>
                        ) : (
                          <span className="notice-me">{t(crumb)}</span>
                        )}
                      </Breadcrumb.Item>
                    </React.Fragment>
                  ))}
                </Breadcrumb>
              </Col>
            </Row>
          </Container>
          <Container
            className="content-background flex-grow-1"
            style={{ marginBottom: "60px" }}
          >
            <Row>
              <Col sx={12}>
                <h1
                  style={{ filter: "drop-shadow(2px 2px 2px #222)" }}
                  className="header-style layout-header"
                >
                  {breadcrumbs[breadcrumbs.length - 1] === "home" &&
                    t("history_of_the_du_preez_in_south_africa")}
                  {breadcrumbs[breadcrumbs.length - 1] !== "home" &&
                    t(breadcrumbs[breadcrumbs.length - 1])}
                </h1>
              </Col>
            </Row>
            {children}
          </Container>
          {/* <Container className="flex-grow-1">{children}</Container> */}
          <footer className="py-4 content-background">
            <Container>
              <Row style={{ textAlign: "center" }}>
                <Col md={12}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("compiled_and_built_by"),
                    }}
                    className="text-muted"
                  />{" "}
                  <Link to="/thys-du-preez" className="">
                    MHC (Thys) du Preez
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col sx={12} md={12} style={{ textAlign: "center" }}>
                  <a
                    href="http://www.facebook.com/pages/History-of-the-Du-Preez-family-in-South-Africa/167279229974158?v=wall"
                    target="_blank"
                    rel="noreferrer"
                    className="text-dark mx-2"
                    style={{ textDecoration: "none" }}
                  >
                    <span className="text-muted">{t("social_media")}: </span>{" "}
                    <i className="bi bi-facebook"></i>
                  </a>
                  {/* <a href="#twitter" className="text-dark mx-2">
                  <i className="bi bi-twitter"></i>
                </a>
                <a href="#linkedin" className="text-dark mx-2">
                  <i className="bi bi-linkedin"></i>
                </a> */}
                </Col>
                <Col sx={12} md={12} style={{ textAlign: "center" }}>
                  <span className="text-muted">
                    {t("copyright")} © {currentYear} Grobler du Preez.{" "}
                    {t("all_rights_reserved")}
                  </span>
                </Col>
                <Col md={12} style={{ textAlign: "center" }}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("built_by"),
                    }}
                    className="text-muted"
                  />
                </Col>
              </Row>
            </Container>
          </footer>
        </div>
      </GoogleReCaptchaProvider>
    </>
  );
};

export default Layout;
