const buildBreadcrum = (linkKey, navKey) => {
  let breadcrumbs = ["home"];
  if (!["", "home", null, undefined].includes(linkKey)) {
    breadcrumbs.push(linkKey);
  }
  if (!["", null, undefined].includes(navKey)) {
    breadcrumbs.push(navKey);
  }
  return breadcrumbs.filter((item, pos) => breadcrumbs.indexOf(item) === pos);
};

const filterObject = (obj, predicate) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => predicate(key, value))
  );
};

const randomId = () => "id" + Math.random().toString(16).slice(2);

export { buildBreadcrum, filterObject, randomId };
