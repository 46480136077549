export const changeRoute = (
  link,
  reactNavigation,
) => {
  if (link.includes("http")) {
    window.open(link, "_blank");
  } else {
    reactNavigation(link);
  }
};
