import { Col, Row, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { changeRoute } from "../../utils/navigation";
import { useState } from "react";
import navigationConfiguration from "../../config/navigation-config.json";
import Detail from './shared/Detail'
import Spouse from "./shared/Spouse";

const FamilyMember = ({ col }) => {
  const [navigate] = useState({
    linkKey: "personal_pages_du_preez_tree",
    navKey: "personal_pages_du_preez_tree_children",
    link: navigationConfiguration.links.personal_pages_du_preez_tree_children,
  });
  const reactNavication = useNavigate();

  const routeChange = (link) => {
    if (link) {
      changeRoute(
        link,
        reactNavication,
      );
    }
  };

  const handleNavigation = (e, link, children) => {
    e.preventDefault();
    if (children) {
      routeChange(link);
    }
  };

  return (
    <>
      <Row
        className={col.children ? "click-click-go" : ""}
        style={{ display: "flex", alignItems: "stretch" }}
        onClick={(e) =>
          handleNavigation(
            e,
            navigate.link,
            col.children
          )
        }
      >
        <Col xs={0} sm={0} md={2}></Col>
        <Col id={`${col.id}-left`} xs={1}></Col>
        <Col
          className={col.children ? "click-click-go-child" : ""}
          style={{
            padding: 0,
            backgroundColor: col.children
              ? "inherit"
              : col.main_line
              ? "#4699eb"
              : "#eb9846",
            border: "#d4dfea solid 1px",
            borderRadius: "10px",
          }}
          id={col.id}
          xs={col.children ? 9 : 10}
          sm={col.children ? 9 : 10}
          md={col.children ? 5 : 6}
          lg={col.children ? 5 : 6}
        >
          <div
            style={{
              padding: "5px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <strong style={{ fontSize: "smaller" }}>{col.level}</strong>
              <span
                style={{
                  fontSize: "smaller",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              >
                {col.number ? "-" : ""}
              </span>
              <span style={{ fontSize: "smaller" }}>{col.number}</span>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <strong>{col.name}</strong>
            </div>
            {col.image && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Image alt="image" src={require(`../../images/${col.image}`)} />
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "smaller",
              }}
            >
              <Detail symbol="*" content={col.born} />
              <Detail symbol="†" content={col.died} />
            </div>
            {col.married &&
              col.married.map((spouse, index) => (
                <Spouse
                  key={`spouse-${col.id}-${index}`}
                  spouse={spouse}
                  number={index + 1}
                />
              ))}
          </div>
        </Col>
        <Col id={`${col.id}-right`} xs={col.children ? 2 : 1}>
          {col.children && <h6 className="continue">Continue the history</h6>}
        </Col>
      </Row>
    </>
  );
};

export default FamilyMember;
