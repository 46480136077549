import { useEffect, useState } from "react";
import { Pagination, Col, Row } from "react-bootstrap";
import CommentCard from "./CommentCard";

const itemsPerPage = 10;

const GuestbookContent = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [visibleStartElips, setVisibleStartElips] = useState(3)
  const [visibleEndElips, setVisibleEndElips] = useState(22)
  const [visiblePages, setVisiblePages] = useState(4)

  useEffect(() => {
    if (document.body.clientWidth > 576) {
      setVisibleStartElips(5)
      setVisibleEndElips(20)
      setVisiblePages(8)
    }
  }, [])

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const renderPageNumbers = () => {
    const halfVisiblePages = Math.floor(visiblePages / 2);

    let startPage = Math.max(currentPage - halfVisiblePages, 1);
    let endPage = startPage + visiblePages - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(endPage - visiblePages + 1, 1);
    }

    return Array.from({ length: endPage - startPage + 1 }, (_, index) => {
      const pageNumber = startPage + index;
      return (
        <Pagination.Item
          key={pageNumber}
          active={currentPage === pageNumber}
          onClick={() => handlePageChange(pageNumber)}
        >
          {pageNumber}
        </Pagination.Item>
      );
    });
  };

  return (
    <>
      <Row>
        {currentItems.map((cardData) => (
          <Col key={`col-${cardData.id}`} sm={12} md={12} lg={6} xl={6}>
            <CommentCard
              cardData={cardData.entry}
            />
          </Col>
        ))}
      </Row>
      <Row className="justify-content-center">
        <Col sm={12}>
          <Pagination className="justify-content-center">
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {currentPage > visibleStartElips && <Pagination.Ellipsis />}
            {renderPageNumbers()}
            {currentPage !== totalPages && currentPage < visibleEndElips && (
              <Pagination.Ellipsis />
            )}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
};

export default GuestbookContent;
