import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const TypingAnimation = (props) => {
  const [wordIndex, setWordIndex] = useState(0);
  const { words } = props;

  useEffect(() => {
    let index = 0;

    const interval = setInterval(() => {
      if (index <= words[wordIndex].length) {
        setWordIndex((prevIndex) =>
          index === words[prevIndex].length
            ? (prevIndex + 1) % words.length
            : prevIndex
        );
        index += 1;
      } else {
        index = 0; // Reset the index to repeat the word
      }
    }, 200);

    return () => clearInterval(interval);
  }, [wordIndex, words]); // Re-run effect when wordIndex changes

  return <h1 style={{filter: 'drop-shadow(2px 2px 2px #222)'}} className="header-style">{words[wordIndex]}</h1>;
};

export default TypingAnimation;
