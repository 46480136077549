import "./spinner.css";

const OverlaySpinner = ({ opacity }) => {
  return (
    <div className="hyp-cont"
      style={{
        opacity: opacity ? opacity : "0.15",
        backdropFilter: "blur(10px)",
        backgroundColor: "rgb(234, 240, 245, 0.5)",
        width: "100%",
        height: "100%",
        zIndex: "99999999",
        top: "0",
        left: "0",
        position: "fixed",
        borderRadius: "15px",
      }}
    >
      <div className="hypnotic-2"></div>
    </div>
  );
};

export default OverlaySpinner;
