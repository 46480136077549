import React, { useState, useEffect } from "react";
import Layout from "../Layout/Layout";
import { Container, Row } from "react-bootstrap";
// import devFamilyData from "../../config/develoment/extendedfamily.json";
import FamilyMember from "./FamilyMember";
import DrawLines from "./DrawLines";
import OverlaySpinner from "../shared/OverlaySpinner";
import Legend from "./shared/Legend";
import familyDataRaw from "../../config/extendedfamily.json";

const FamilyTree = () => {
  const [drawArrows, setDrawArrows] = useState(false);
  const [familyData, setFamilyData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        // if (window.location.hostname === "nahnah") {
        //   setFamilyData(devFamilyData);
        // } else {
        //   const response = await fetch(
        //     `${process.env.REACT_APP_GET_DATA_BASE_URL}?field=extendedfamily`
        //   );

        //   if (response.ok) {
        //     const resultJSON = await response.json();
        //     setFamilyData(resultJSON[0]["extendedfamily"]);
        //   } else {
        //     console.error("Error fetching data for family tree, see function");
        //   }
        // }
        setFamilyData(familyDataRaw["extendedfamily"]);
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setTimeout(() => {
          setDrawArrows(true);
        }, 3000);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <Layout>
      {loading && <OverlaySpinner opacity={1} />}
      {!loading && (
        <Container>
          <Row style={{ marginBottom: "50px" }}>
            <Legend />
          </Row>
          {familyData &&
            Object.keys(familyData).map((key) => (
              <React.Fragment key={`fragment-${key}`}>
                {familyData[key] && (
                  <>
                    <div
                      style={{ marginBottom: "110px" }}
                    >
                      {familyData[key].map((col) => (
                        <FamilyMember
                          key={`custom-${key}-${col.id}`}
                          col={col}
                        />
                      ))}
                    </div>
                    <DrawLines
                      draw={drawArrows}
                      familyData={familyData}
                      indexKey={key}
                    />
                  </>
                )}
              </ React.Fragment>
            ))}
        </Container>
      )}
    </Layout>
  );
};

export default FamilyTree;
