import Xarrow from "react-xarrows";
import OverlaySpinner from "../shared/OverlaySpinner";
import React from "react";

const CustomXarrow = ({ start, end, sAnchor, eAnchor, path, strokeWidth=1, showHead=false, dashness=false, headSize=6, lineColor="#192634", headColor="#192634" }) => {
  let sElement = document.getElementById(start);
  let eElement = document.getElementById(end);

  return (
    sElement &&
    eElement && (
      <Xarrow
        start={start}
        end={end}
        lineColor={lineColor}
        headColor={headColor}
        strokeWidth={strokeWidth}
        curveness={1}
        dashness={dashness}
        startAnchor={sAnchor}
        endAnchor={eAnchor}
        path={path}
        showHead={showHead}
        headSize={headSize}
      />
    )
  );
};

const DrawLines = ({ draw, familyData, indexKey }) => {
  const parentLevel = familyData[indexKey];
  const childLevel = familyData[parseInt(indexKey) + 1];
  const sideKey = parseInt(indexKey) - 1;
  const parentSide = sideKey % 2 === 0 ? "right" : "left";
  const childSide = sideKey % 2 !== 0 ? "right" : "left";
  const mainParent = parentLevel.find((item) => item.main_line);

  return (
    <>
      {!draw && (
        <OverlaySpinner />
      )}
      {draw && (
        <>
          {childLevel && (
            <CustomXarrow
              start={`${mainParent.id}-${parentSide}`}
              end={`${childLevel[childLevel.length - 1].id}-${parentSide}`}
              sAnchor={parentSide}
              eAnchor={parentSide}
              path="grid"
            />
          )}
          {parentLevel.map((level) => (
            <React.Fragment key={`key-${level.id}`}>
              {parseInt(indexKey) !== 1 && (
                <CustomXarrow
                  start={level.id}
                  end={`${level.id}-${childSide}`}
                  sAnchor={childSide}
                  eAnchor={childSide}
                  path="smooth"
                />
              )}
              {level.main_line && childLevel && (
                <CustomXarrow
                  start={level.id}
                  end={`${level.id}-${parentSide}`}
                  sAnchor={parentSide}
                  eAnchor={parentSide}
                  path="smooth"
                />
              )}
              {level.main_line && !childLevel && (
                <CustomXarrow
                  start={level.id}
                  end={`${level.id}-${parentSide}`}
                  sAnchor={parentSide}
                  eAnchor={parentSide}
                  path="smooth"
                  strokeWidth={8}
                  showHead={true}
                  dashness={false}
                  lineColor="#4699eb"
                  headColor="#4699eb"
                />
              )}
            </ React.Fragment>
          ))}
        </>
      )}
    </>
  );
};

export default DrawLines;
