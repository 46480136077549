import { Col, Container, Row, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Layout from "../Layout/Layout";
import thys from "../../images/thys.avif"

const ThysDuPreez = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Container>
        <Row>
          <Col md={12} style={{textAlign: 'center'}}>
            <Image
              alt="Thys Du Preez"
              src={thys}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} style={{textAlign: 'center', paddingTop: '20px'}}>
            <p
              dangerouslySetInnerHTML={{
                __html: t("thys_du_preez_content"),
              }}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default ThysDuPreez;
